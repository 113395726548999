export const scrollDialogMixin = {
  data() {
    return {
      className: ''
    }
  },
  methods: {
    randerClassName() {
      this.$nextTick(() => {
        const height = this.$refs.scrollBody.scrollHeight
        if (height > 600) {
          this.className = 'scroll-dialog'
        } else {
          this.className = ''
        }
      })
    }
  }
}