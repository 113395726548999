<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="570px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :custom-class="className"
    :before-close="hideDialog">
    <div ref="scrollBody">
      <div class="form-item flex flex-center">
        <span class="form-label">
          <span class="must">*</span>
          所属产品
        </span>
        <el-select class="flex-1" v-model="form.productId" placeholder="请选择" size="small" @change="changeProduct">
          <el-option
            v-for="item in productList"
            :key="item.productId"
            :label="item.productName"
            :value="item.productId">
          </el-option>
        </el-select>
      </div>
      <div class="form-item flex flex-center">
        <span class="form-label">
          <span class="must">*</span>
          产品版本
        </span>
        <el-select class="flex-1" v-model="form.productIdList" multiple placeholder="请选择" size="small" @change="changeVersion">
          <el-option
            v-for="item in versionList"
            :key="item.productId"
            :label="item.versionName"
            :value="item.productId">
          </el-option>
        </el-select>
      </div>
      <div class="form-item flex flex-center">
        <span class="form-label">
          <span class="must">*</span>
          权限名称
        </span>
        <el-input class="flex-1" v-model="form.note" size="small" minlength="2" maxlength="16" show-word-limit></el-input>
      </div>
      <div class="form-item flex flex-center">
        <span class="form-label">
          <span class="must">*</span>
          上级菜单
        </span>
        <el-popover
          v-model="treeSelectOpen"
          class="flex-1"
          placement="bottom-start"
          width="346"
          transition="el-zoom-in-top"
          trigger="click">
          <el-tree
            :data="authTree"
            ref="authTree"
            node-key="id"
            :props="defaultProps"
            @node-click="handleNodeClick"
            v-if="visible"
          >
          </el-tree>
          <div slot="reference" class="tree-select flex flex-center">
            <span class="flex-1">{{form.parentName}}</span>
            <i class="el-icon-arrow-down" style="color: #C0C4CC;"></i>
          </div>
        </el-popover>
      </div>
      <div class="form-item flex flex-center">
        <span class="form-label">
          <span class="must">*</span>
          权限属性
        </span>
        <el-select class="flex-1" v-model="form.permissionAttr" placeholder="请选择" size="small">
          <el-option
            v-for="item in permissionAttrOption"
            :key="item.k"
            :label="item.v"
            :value="item.k">
          </el-option>
        </el-select>
      </div>
      <div class="form-item flex flex-center">
        <span class="form-label">
          <span class="must">*</span>
          操作标识code
        </span>
        <el-input class="flex-1" v-model="form.configKey" size="small" :disabled="!!authId"></el-input>
      </div>
      <div class="form-item flex flex-center" v-show="form.permissionAttr != 1">
        <span class="form-label">
          <span class="must">*</span>
          权限类型
        </span>
        <el-select class="flex-1" v-model="form.permissionType" placeholder="请选择" size="small" disabled>
          <el-option
            v-for="item in permissionTypeOption"
            :key="item.k"
            :label="item.v"
            :value="item.k">
          </el-option>
        </el-select>
      </div>
      <div class="form-item flex flex-center" v-show="form.permissionAttr != 1">
        <span class="form-label">
          <span class="must">*</span>
          所属项目
        </span>
        <el-select class="flex-1" v-model="form.belongProject" placeholder="请选择" size="small">
          <el-option
            v-for="item in belongProjectOption"
            :key="item.k"
            :label="item.v"
            :value="item.k">
          </el-option>
        </el-select>
      </div>
      <div class="form-item flex flex-center">
        <span class="form-label">
          <span class="must">*</span>
          是否生效
        </span>
        <el-select class="flex-1" v-model="form.flag" placeholder="请选择" size="small">
          <el-option label="是" :value="true"></el-option>
          <el-option label="否" :value="false"></el-option>
        </el-select>
      </div>
      <div class="form-item flex flex-center" v-show="!form.flag">
        <span class="form-label">
          <span class="must">*</span>
          显示设置
        </span>
        <el-select class="flex-1" v-model="form.showFlag" placeholder="请选择" size="small">
          <el-option
            v-for="item in showFlagOption"
            :key="item.k"
            :label="item.v"
            :value="item.k">
          </el-option>
        </el-select>
      </div>
      <div class="form-item flex flex-center">
        <span class="form-label self-start">
          权限说明
        </span>
        <el-input type="textarea" class="flex-1" v-model="form.description" size="small" maxlength="255" rows="5" show-word-limit></el-input>
      </div>
    </div>
    <div slot="footer">
      <el-button size="small" @click="hideDialog">取消</el-button>
      <el-button type="primary" size="small" @click="confirm" :disabled="loading">
        保存
        <i class="el-icon-loading" v-show="loading"></i>
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { get_sys_permission_detail, get_upper_menu, save_sys_permission, update_sys_permission } from '@/api/base'
import { FormValid } from "@/utils/formValid";
import { get_key_value } from '@/api/common'
import { scrollDialogMixin } from '@/mixins/scrollDialogMixin'

const form = {
  productId: '',
  note: '',
  parentId: '',
  permissionAttr: '1',
  configKey: '', // 操作标识code 规则：一级菜单：模块名称+ ':manager:menu'；二级菜单：模块名称+ 二级模块名称+':menu'；其他按钮：模块名称+按钮功能：':btn'
  permissionType: '1',
  belongProject: '',
  flag: false,
  showFlag: '0',
  description: ''
}
export default {
  mixins: [scrollDialogMixin],
  props: {
    visible: {
      type: Boolean
    },
    authId: {
      type: String
    },
    productList: {
      type: Array,
      default: () => {
        return []
      }
    },
    permissionAttrOption: {
      type: Array,
      default: () => {
        return []
      }
    },
    permissionTypeOption: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  data() {
    return {
      showFlagOption: [],
      form: this.$deepClone(form),
      versionList: [],
      belongProjectOption: [],
      authTree: [],
      defaultProps: {
        children: 'child',
        label: 'note'
      },
      treeSelectOpen: false,
      loading: false
    }
  },
  watch: {
    visible(val) {
      if (val) {
        if (this.authId) {
          this.getAuthDetail()
        } else {
          this.form.productId = this.productList[0].productId
          this.changeProduct(this.form.productId)
        }
      } else {
        this.form = this.$deepClone(form)
      }
    },
    form: {
      deep: true,
      handler() {
        this.randerClassName()
      }
    }
  },
  computed: {
    title() {
      return this.authId ? '编辑权限' : '新增权限'
    },
    checkFormList() {
      let arr = [
        {
          label: '所属产品',
          value: 'productId',
          methods: ['notEmpty']
        },
        {
          label: '产品版本',
          value: 'productIdList',
          methods: ['notEmpty']
        },
        {
          label: '权限名称',
          value: 'note',
          methods: ['notEmpty']
        },
        {
          label: '上级菜单',
          value: 'parentName',
          methods: ['notEmpty']
        },
        {
          label: '权限属性',
          value: 'permissionAttr',
          methods: ['notEmpty']
        },
        {
          label: '操作标识code',
          value: 'configKey',
          methods: ['notEmpty']
        }
      ]
      if (this.form.permissionAttr != 1) {
        arr = arr.concat([
          {
            label: '所属项目',
            value: 'belongProject',
            methods: ['notEmpty']
          }
        ])
      }
      return arr
    }
  },
  mounted() {
    this.getOption()
  },
  methods: {
    getOption() {
      get_key_value({
        type: 'BELONG_PROJECT',
        size: 100
      }).then(res => {
        this.belongProjectOption = res ? res.records : []
      })
      get_key_value({
        type: 'SHOW_FLAG'
      }).then(res => {
        this.showFlagOption = res ? res.records : []
      })
    },
    getAuthDetail() {
      get_sys_permission_detail({
        id: this.authId
      }).then(res => {
        this.form = res || this.$deepClone(form)
        this.form.permissionAttr = this.form.permissionAttr + ''
        this.form.permissionType = this.form.permissionType == -1 ? '1' : this.form.permissionType + ''
        this.form.showFlag = this.form.showFlag == -1 ? '0' : this.form.showFlag + ''
        this.form.parentName = this.form.parentNote || '空'
        this.productList.map(o => {
          const index = o.productList && o.productList.findIndex(v => { return v.productId == this.form.productIdList[0] })
          if (index > -1) {
            this.form.productId = o.productId
          }
        })
        this.changeProduct(this.form.productId, false)
      })
    },
    getUpperMenu() {
      get_upper_menu({
        productIdList: this.form.productIdList.join(',')
      }).then(res => {
        this.authTree = res || []
        if (!this.authId) {
          this.handleNodeClick(this.authTree[0] || {})
        }
      })
    },
    changeProduct(value, change = true) {
      console.log(value)
      const item = this.productList.find(o => { return o.productId == value })
      if (item) {
        this.versionList = item.productList
        if (change) {
          this.form.productIdList = [this.versionList[0].productId]
        }
      }
      this.getUpperMenu()
    },
    changeVersion(value) {
      console.log(value)
      this.getUpperMenu()
    },
    handleNodeClick(data) {
      console.log(data)
      this.treeSelectOpen = false
      this.form.parentName = data.note
      this.form.parentId = data.id
    },
    hideDialog() {
      this.$emit('close')
    },
    checkForm() {
      const formValid = new FormValid();
      for (const f of this.checkFormList) {
        const { label, value, methods } = f;
        const v = this.form[value]
        formValid.add(label, v, methods)
      }
      const res = formValid.start();
      if (res !== true) {
        this.$message.warning(res)
        return false;
      }
      if (this.form.note.length < 2) {
        this.$message.warning('权限名称长度须在2-16位')
        return false
      }
      return {
        ...this.form
      };
    },
    confirm() {
      let params = this.checkForm()
      if (!params) return
      if (params.permissionAttr == 1) {
        params.permissionType = -1
        delete params.belongProject
      }
      if (params.flag === true) {
        params.showFlag = -1
      }
      params.productId = params.productIdList.join(',')
      delete params.productIdList
      delete params.parentName
      delete params.parentNote
      console.log(params)
      this.loading = true
      if (this.authId) {
        delete params.child
        update_sys_permission(params).then(() => {
          this.loading = false
          this.$message.success('保存成功')
          this.$emit('confirm')
        }).catch(() => {
          this.loading = false
        })
      } else {
        save_sys_permission(params).then(() => {
          this.loading = false
          this.$message.success('保存成功')
          this.$emit('confirm')
        }).catch(() => {
          this.loading = false
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-item{
  padding-left: 7px;
  margin-bottom: 16px;
  .must{
    color: #E14B38
  }
  .form-label{
    width: 100px;
    text-align: right;
    padding-right: 20px;
  }
}

.tree-select{
  height: 30px;
  line-height: 30px;
  background-color: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  color: #606266;
  padding: 0 10px 0 15px;
  font-size: 13px;
}
</style>