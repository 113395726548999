<template>
  <el-dialog
    title="授权"
    :visible.sync="visible"
    width="570px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="hideDialog">
    <div>
      <div class="dialog-tips">
        <div><i class="el-icon-warning-outline"></i>请选择初始化角色进行授权，授权后，酒店系统的相应角色将拥有该权限。</div>
      </div>
      <div class="flex flex-center">
        <span>权限名称</span>
        <el-input class="flex-1 m-l-16" :value="authName" disabled size="small"></el-input>
      </div>
      <el-table
        class="m-t-16"
        :data="list"
        ref="roleTable"
        style="width: 100%"
        border
        header-row-class-name="table-header"
        :row-class-name="rowSelectedClassName"
        @selection-change="handleSelect"
      >
        <el-table-column
          type="selection"
          align="center"
          :selectable="randerSelectable"
          width="55">
        </el-table-column>
        <el-table-column
          align="center"
          prop="roleName"
          label="角色名称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="description"
          label="角色说明"
        ></el-table-column>
      </el-table>
    </div>
    <div slot="footer">
      <el-button size="small" @click="hideDialog">取消</el-button>
      <el-button type="primary" size="small" @click="confirm" :disabled="loading">
        确认授权
        <i class="el-icon-loading" v-show="loading"></i>
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { get_hotel_role, bind_role_permission } from '@/api/base'

export default {
  props: {
    visible: {
      type: Boolean
    },
    authId: {
      type: String
    },
    authName: {
      type: String
    }
  },
  data() {
    return {
      list: [],
      selection: [],
      loading: false
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.getRole()
      }
    }
  },
  computed: {
  },
  methods: {
    getRole() {
      this.cancelSelection()
      get_hotel_role({
        id: this.authId
      }).then(res => {
        this.list = res
        this.$nextTick(() => {
          this.list.map(o => {
            if (o.checked) {
              this.$refs.roleTable.toggleRowSelection(o)
            }
          })
        })
      })
    },
    randerSelectable(row) {
      return row.roleCode !== 'R00'
    },
    handleSelect(selection) {
      console.log(selection)
      this.selection = selection
    },
    cancelSelection() {
      this.$refs.roleTable && this.$refs.roleTable.clearSelection()
    },
    rowSelectedClassName({ row }) {
      const index = this.selection.findIndex(o => { return o.id == row.id })
      if (index > -1) {
        return 'selected-row';
      }
      return '';
    },
    hideDialog() {
      this.$emit('close')
    },
    confirm() {
      // if (!this.selection.length) return this.$message.warning('请选择角色')
      let params = {
        id: this.authId,
        roleIdList: this.selection.map(o => { return o.id })
      }
      this.loading = true
      bind_role_permission(params).then(() => {
        this.loading = false
        this.$message.success('授权成功')
        this.cancelSelection()
        this.hideDialog()
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body{
  padding-top: 0;
}
</style>