<template>
  <div>
    <div class="flex">
      <div class="page-content" style="min-width: 170px">
        <div class="page-sub-title flex flex-center">
          <span class="flex-1">系统选择</span>
        </div>
        <div>
          <div class="product-item" v-for="item in productList" :key="item.productId" :class="{'current': productId == item.productId}" @click="changeProduct(item)">
            <span class="logo"></span>{{item.productName}}
          </div>
        </div>
      </div>
      <div class="page-content m-l-16 flex-auto" style="overflow: auto">
        <div class="page-sub-title flex flex-center">
          <span class="flex-1">筛选查询</span>
        </div>
        <div>
          <div class="search-item">
            <span class="label">权限名称或说明</span>
            <el-input v-model="searchParams.search" size="small" clearable style="width: 240px" placeholder="请输入权限名称或说明"></el-input>
          </div>
          <div class="search-item">
            <el-button type="primary" size="small" @click="search">查询</el-button>
            <el-button class="border-btn" plain size="small" @click="reset">重置</el-button>
          </div>
        </div>
        <div class="page-sub-title flex flex-center">
          <span class="flex-1">权限管理</span>
          <el-button type="primary" size="small" @click="showEditDialog()">+ 新增权限</el-button>
        </div>
        <el-table
          :data="list"
          style="width: 100%"
          border
          row-key="id"
          header-row-class-name="table-header"
          :tree-props="treeProps"
        >
          <el-table-column
            prop="note"
            label="权限名称"
            min-width="170"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="permissionAttr"
            label="权限属性"
          >
            <template slot-scope="scope">
              {{permissionAttrMap[scope.row.permissionAttr]}}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="权限类型"
          >
            <template slot-scope="scope">
              {{scope.row.permissionAttr == 1 || scope.row.permissionType == -1 ? '-' : permissionTypeMap[scope.row.permissionType]}}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="上级菜单"
          >
            <template slot-scope="scope">
              {{scope.row.parentId == -1 ? '-' : scope.row.parentNote}}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="configKey"
            label="操作标识code"
            min-width="170"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="belongProjectCN"
            label="所属项目"
          ></el-table-column>
          <el-table-column
            align="center"
            label="是否生效"
          >
            <template slot-scope="scope">
              <span class="point" :style="{'background': flagColor[scope.row.flag ? 1 : 2]}"></span>
              {{scope.row.flag ? '是' : '否'}}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="description"
            label="权限说明"
            min-width="170"
          ></el-table-column>
          <el-table-column
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="showEditDialog(scope.row)">编辑</el-button>
              <el-button type="text" @click="showAuthDialog(scope.row)">授权</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <edit-auth-dialog :visible="editVisible" :authId="authId" :productList="productList" :permissionAttrOption="permissionAttrOption" :permissionTypeOption="permissionTypeOption" @close="hideEditDialog" @confirm="confirmEditDialog"></edit-auth-dialog>

    <auth-role-dialog :visible="authVisible" :authId="authId" :authName="authName" @close="hideAuthDialog"></auth-role-dialog>
  </div>
</template>

<script>
import { get_product_list } from "@/api/product";
import { get_sys_permission } from '@/api/base'
import { get_key_value } from '@/api/common'
import editAuthDialog from './component/edit-auth-dialog'
import authRoleDialog from './component/auth-role-dialog'

export default {
  name: 'hotel-system-auth',
  components: {
    editAuthDialog,
    authRoleDialog
  },
  data() {
    return {
      permissionAttrOption: [],
      permissionAttrMap: {},
      permissionTypeOption: [],
      permissionTypeMap: {},
      flagColor: {
        1: '#67C23A',
        2: '#A4A4A4'
      },
      productId: '',
      searchParams: {
        search: ''
      },
      list: [],
      listAll: [],
      productList: [],
      treeProps: {
        children: 'child'
      },
      editVisible: false,
      authId: null,
      authVisible: false,
      authName: null
    }
  },
  mounted() {
    this.getOption()
    this.getProduct()
  },
  methods: {
    getOption() {
      get_key_value({
        type: 'GLOBAL_PERMISSION_ATTR'
      }).then(res => {
        this.permissionAttrOption = res ? res.records : []
        this.permissionAttrMap = this.$arrayToMap(this.permissionAttrOption)
      })
      get_key_value({
        type: 'PERMISSION_TYPE'
      }).then(res => {
        this.permissionTypeOption = res ? res.records : []
        this.permissionTypeMap = this.$arrayToMap(this.permissionTypeOption)
      })
    },
    getProduct() {
      get_product_list()
        .then(res => {
          this.productList = res
          this.productId = res[0] ? res[0].productId : ''
          this.getList()
        })
    },
    getList() {
      if (!this.productId) {
        this.list = []
        return
      }
      get_sys_permission({
        productId: this.productId
      }).then(res => {
        console.log(res)
        if (res) {
          this.list = res
          this.listAll = this.list.concat()
          this.search()
        } else {
          this.list = []
        }
      })
    },
    search() {
      this.list = this.listAll.filter(o => {
        const search = this.searchParams.search
        if (search) {
          const child = o.child
          o.child = child && child.filter(v => {
            const c = v.child
            v.child = c && c.filter(n => {
              return n.note.indexOf(search) > -1 || n.description.indexOf(search) > -1
            })
            return v.note.indexOf(search) > -1 || v.description.indexOf(search) > -1 || (v.child && v.child.length > 0)
          })
          return o.note.indexOf(search) > -1 || o.description.indexOf(search) > -1 || (o.child && o.child.length > 0)
        } else {
          return true
        }
      })
    },
    reset() {
      this.searchParams.search = ''
      this.list = this.listAll.concat()
    },
    changeProduct(item) {
      this.searchParams.note = ''
      this.productId = item.productId
      this.getList()
    },
    showEditDialog(row) {
      if (row) {
        this.authId = row.id + ''
      } else {
        this.authId = null
      }
      this.editVisible = true
    },
    hideEditDialog() {
      this.authId = null
      this.editVisible = false
    },
    confirmEditDialog() {
      this.hideEditDialog()
      this.getList()
    },
    showAuthDialog(row) {
      this.authVisible = true
      this.authId = row.id + ''
      this.authName = row.note
    },
    hideAuthDialog() {
      this.authId = null
      this.authName = null
      this.authVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.product-item{
  cursor: pointer;
  padding: 5px 10px;
  &.current{
    color: $color;
    background: #F5F7FA;
  }
  .logo{
    background: url('../../assets/icons/logo-yhj.png') no-repeat center center/100% 100%;
    width: 12px;
    height: 13px;
    display: inline-block;
    margin-right: 4px;
    vertical-align: -1px;
  }
}
</style>